export default [
    {
        label: 'No.',
        field: 'orderNumber',
        sort: true
    },
    {
        label: '진단명',
        field: 'diagnosis',
        sort: true
    },
    {
        label: '손상일',
        field: 'injuredDate',
        sort: false,
    },
    {
        label: '손상부위',
        field: 'injuredPart',
        sort: false,
    },
    {
        label: '회차',
        field: 'round',
        sort: false
    },
    {
        label: '평가일자',
        field: 'regDate',
        sort: true
    },

];
