export default [
    {
        label: 'No.',
        field: 'orderNumber',
        sort: true
    },
    {
        label: '이름',
        field: 'name',
        sort: true
    },
    {
        label: '아이디',
        field: 'id',
        sort: false
    },
    {
        label: '치료병원',
        field: 'hospitalName',
        sort: false,
    },
    {
        label: '담당치료사',
        field: 'therapistName',
        sort: false,
    },
    {
        label: '최근 접속 일자',
        field: 'lastLog',
        sort: false
    },
    {
        label: '정보조회',
        field: 'action',
    },
];
