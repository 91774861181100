<template>
  <div class="main-container">
    <h5 class="main-title">환자 목록</h5>
    <div class="search-container">
      <h5 class="search-title">총 환자({{ dataCnt }})</h5>
      <div class="search-box-first">
        <p>활동중이지 않은 사용자 조회 일 간격</p>
        <mdb-select
            outline
            v-model="countOptions"
            @getValue="getSelectDateValue"
            class="search-select"/>
      </div>
      <div class="search-box">
        <p class="search-sub-title">환자 검색</p>
        <mdb-select
            :scroll=false
            outline
            v-model="basicOptions"
            @getValue="getSelectMainValue"
            class="search-select "/>
        <div class="icon-input">
          <img src="@/assets/images/IA/icon_search.png" alt="search">
          <input v-model="search" placeholder="검색어를 입력하세요." class="custom-input-box search-input"/>
        </div>
        <button class="custom-btn-box search-btn" @click="doSearch">
          <img src="@/assets/images/IA/icon_user_2.png" alt="user">
          환자 검색
        </button>
      </div>
    </div>

    <div class="table-container">
      <mdb-datatable-2
          v-model="data"
          multiselectable
          class="text-center"
          borderless
          hover
          :searching="{value: search, field: fieldName} "
          @selected.capture="onSelected($event)"
          noFoundMessage="데이터가 없습니다."/>
    </div>
    <mdb-datatable-2
        v-show="false"
        :pagination="false"
        v-model="excelData"
        class="text-center"
        id="data-table"
        borderless
        noFoundMessage="선택하신 데이터가 없습니다."/>
    <div class="sub-container">
      <button class="custom-btn-box exel-btn" @click="excelDownload">
        <img src="@/assets/images/IA/excel-download.png" alt="excel">
        Excel Download
      </button>
    </div>
    <div class="sub-container">
      <p v-html="patientsName"></p>
    </div>
    <div class="result-null-container" v-if="isDataNull">
      <img src="@/assets/images/IA/img_null.png" alt="null">
      <h5>조회할 환자를 선택해주세요.</h5>
    </div>
    <PatientDetailList v-else :dataPatientDetailList="dataPatientDetailList" @refresh="refresh"/>
  </div>
</template>

<script>
import {mdbDatatable2, mdbSelect} from "mdbvue";
import patients_columns from "@/assets/data/master/patients/patients_columns";
import PatientDetailList from "@/views/master/patients/components/PatientDetailList";
import {firebase} from "@/firebase/firebaseConfig";
import {getDate} from "@/lib/prettyDate";
import * as XLSX from "xlsx";
import patients_excel_columns from "@/assets/data/master/patients/patients_excel_columns";
import patients_detail_columns from "@/assets/data/master/patients/patients_detail_columns";

export default {
  name: "PatientsList",
  components: {
    PatientDetailList,
    mdbSelect,
    mdbDatatable2,
  },
  data() {
    return {
      fbCollection: 'patients',
      data: {
        columns: patients_columns,
        rows: []
      },
      basicOptions: [
        {text: "이름", value: "name", selected: true},
        {text: "아이디", value: "id"},
        {text: "치료병원", value: "hospitalName"},
      ],
      countOptions: [
        {text: "제한없음", value: 0, selected: true},
        {text: "지난 일주일", value: 7},
        {text: "지난 한달", value: 30},
        {text: "지난 1년이상", value: 365},
      ],
      search: '',
      fieldName: 'name',
      isDataNull: true,
      patientsName: '환자 정보',
      selData: {},
      excelData: {
        columns: patients_excel_columns,
        rows: []
      },
      selExelData: [],
      countVal: 0,
      selectStartDate: '',
      selectEndDate: '',
      dataCnt: 0,
      dataPatientDetailList: {
        columns: patients_detail_columns,
        rows: []
      },
    }
  },
  created() {
    window.onSelected2 = this.onSelected2;
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getDateStr(myDate) {
      const year = myDate.getFullYear();
      const month = ("00" + (myDate.getMonth() + 1)).slice(-2)
      const day = ("00" + myDate.getDate()).slice(-2)
      return year + '-' + month + '-' + day
    },
    getSelectDateValue(value) {
      const self = this;
      let now = new Date();
      self.selectEndDate = this.getDateStr(now)
      const dDay = now.getDate();
      switch (value) {
        case 0 : {
          self.selectEndDate = '';
          self.selectStartDate = '';
          return
        }
        default :
          now.setDate(dDay - value);
          self.selectStartDate = this.getDateStr(now)
          return
      }
    },
    doSearch() {
      const self = this;
      self.data.rows.splice(0)
      let count = 0;
      let docRef = firebase.firestore().collection(self.fbCollection);
      if (self.selectStartDate !== '' && self.selectStartDate !== undefined) {
        let start = new Date(self.selectStartDate);
        start.setHours(0, 0, 0, 0)
        docRef = docRef
            .where('lastLog', '<=', start)
            .orderBy('lastLog', 'desc')
      } else {
        docRef = docRef
            .orderBy('regDate', 'desc')
      }

      docRef
          .get().then(async (querySnapshot) => {
        if (querySnapshot.size !== 0) {
          self.dataCnt = querySnapshot.size
          querySnapshot.forEach((doc) => {
            count++;
            const _data = doc.data();
            _data.docId = doc.id;
            _data.orderNumber = count;
            if (_data.lastLog != null) {
              const date = new Date(_data.lastLog.seconds * 1000);
              _data.lastLog = getDate(date);
            } else {
              _data.lastLog = '미접속'
            }
            _data.name = _data.userInfo.name;
            _data.id = _data.userInfo.id;
            _data.action = `<p class="blue-a-color" onclick="onSelected2(event, '${doc.id}')">조회</p>`
            self.data.rows.push(_data);
          });
        } else {
          self.dataCnt = 0;
        }
      });
    },
    getSelectMainValue(value) {
      const self = this;
      self.fieldName = value;
    },
    getSelectCountValue(value) {
      const self = this;
      self.countVal = value;
    },
    onSelected(event) {
      const self = this;
      self.excelData.rows.splice(0)
      self.selExelData = event;
      for (let i = 0; i < self.selExelData.length; i++) {
        self.excelData.rows.push(self.selExelData[i])
      }
    },
    onSelected2(event, docId) {
      event.stopPropagation()
      const self = this;
      if (self.selDocId === docId) {
        self.therapistName = '환자 정보'
        self.selDocId = ''
        return
      }
      self.selDocId = docId
      self.selData = self.data.rows.find((element) => element.docId === docId)
      self.therapistName = self.selData.name
      self.isDataNull = false

      self.initPatientData()
    },
    initPatientData() {
      const self = this;
      self.dataPatientDetailList.rows.splice(0)
      const db = firebase.firestore();
      let orderNumber = 0;
      db.collection('evaluations')
          .where('patientInfo.patientKey', '==', self.selData.docId)
          .orderBy('regDate', 'desc')
          .get().then((querySnapshot) => {
        if (querySnapshot.size !== 0) {
          querySnapshot.forEach((doc) => {
            orderNumber++;
            const _data = doc.data();
            _data.docId = doc.id;
            _data.orderNumber = orderNumber;
            const date = new Date(_data.regDate.seconds * 1000);
            _data.regDate = getDate(date);
            _data.diagnosis = _data.diseaseInfo.diagnosis;
            _data.injuredDate = _data.diseaseInfo.injuredDate;
            _data.injuredPart = _data.diseaseInfo.injuredPart;
            self.dataPatientDetailList.rows.push(_data);
          });
        }
      });
    },


    refresh() {
    },

    excelDownload() {
      const self = this;
      if(self.excelData.rows.length === 0) return alert('선택된 환자가 없습니다.');

      let excelData = XLSX.utils.table_to_sheet(document.getElementById('data-table')); // table id
      let workBook = XLSX.utils.book_new(); // 새 시트 생성

      const d = new Date();
      const today = d.getFullYear()
          + "-" + ("00" + (d.getMonth() + 1)).slice(-2)
          + "-" + ("00" + d.getDate()).slice(-2)
          + " " + ("00" + d.getHours()).slice(-2)
          + ":" + ("00" + d.getMinutes()).slice(-2)
          + ":" + ("00" + d.getSeconds()).slice(-2)

      XLSX.utils.book_append_sheet(workBook, excelData, 'nameData');  // 시트 명명, 데이터 지정
      XLSX.writeFile(workBook, today + '.xlsx'); // 엑셀파일 생성
    },
  }
}
</script>

<style scoped>
.main-container {
  background: white;
  padding: 44px 20px;
  min-width: 890px;
}

.main-title {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 30px;
}

.search-box-first {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 18px;
}

.search-box-first p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #272833;
  margin-right: 20px;
}

.search-container {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 23px 20px;
}

.search-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin: 0 0 10px 0;
}

.search-sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #272833;
  margin: 0 8px 0 0;
  min-width: 100px;
}

.search-btn {
  max-width: 161px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.search-btn img {
  margin-right: 6px;
  width: 18px;
}

/deep/ .caret {
  display: none;
}

/deep/ .search-select .md-form {
  width: 151px;
  padding: 0 !important;
  margin: 0 !important;
  background: url('../../../assets/images/IA/icon_down.png') no-repeat right 9px center;
  background-size: 18px 18px;
}

/deep/ .search-select .form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  letter-spacing: 0.0025em;
  color: #999999;
  margin: 0;
}

.search-input {
  padding-left: 30px;
  background: #FFFFFF;
  font-size: 16px;
}

.icon-input {
  width: 100%;
  margin: 0 10px;
  position: relative;
}

/deep/ .icon-input ::placeholder {
  font-size: 16px !important;
}

.icon-input img {
  position: absolute;
  top: 11px;
  left: 8px;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.count-select {
  margin: 0 10px 0 15px;
}

/deep/ .count-select .md-form {
  width: 105px !important;

}

.sub-container p {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833;
}

.exel-btn {
  width: 185px;
  height: 40px;
  background: #08A86E;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #FFFFFF;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-bottom: 22px;
}

.exel-btn img {
  width: 18px;
  margin: 0 15px 0 10px;
}

.table-container {
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 13px;
  margin-bottom: 13px;
}

.result-null-container {
  margin-top: 15px;
  width: 100%;
  height: 276px;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.result-null-container img {
  width: 101px;
  margin-bottom: 16px;
}

.result-null-container h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
  margin: 0;
}

/deep/ .blue-a-color {
  color: #0069CA;
  text-decoration: underline;
  cursor: pointer;
}


</style>
